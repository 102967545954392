<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :style="{ maxHeight: maxHeight }"
  />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartDataLabels,
);

export default {
  name: "BarChart",
  constants: {
    CONFIG: {
      plugins: {
        common: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          }
        },
        data_labels: {
          tooltip: {
            enabled: false,
          },
          datalabels: {
            anchor: 'end',
            display: true,
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 18,
                },
              },
            }
          }
        },
      },
    }
  },
  components: { Bar },
  props: {
    wDataLabels: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    values: {
      type: Array,
      default: () => [],
    },
    maxHeight: {
      type: String,
      default: "30vh",
    },
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: [{ data: this.values }],
      },
      chartOptions: {
        plugins: {},
        maintainAspectRatio: false,
        borderWidth: 2,
        responsive: true,
        backgroundColor: ["#91CBEA"],
        scales: {
          y: {
            ticks: {
              precision: 0,
            },
          },
        },
      },
    };
  },
  mounted() {
    this.$set(this.chartOptions, 'plugins', this.plugins())
  },
  methods: {
    plugins() {
      let plugins = Object.assign({}, this.$options.constants.CONFIG.plugins.common);
      if (!!this.wDataLabels)
        plugins = Object.assign(plugins, this.$options.constants.CONFIG.plugins.data_labels)

      return plugins;
    }
  },
};
</script>
